var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside === 'voucher_group')?_c('voucher-group-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.voucherGroupData},on:{"changed":_vm.refresh,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),(_vm.aside === 'generator')?_c('voucher-group-generator-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.voucherGroupData},on:{"changed":_vm.voucherUpdated,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('div',[_c('span',[_vm._v(_vm._s(_vm.sku.name.en)+"'s Voucher Groups")]),_c('v-chip',{staticClass:"ml-2"},[_vm._v(" STOCK: "+_vm._s(_vm.sku.stock)+" ")])],1),_c('v-spacer'),((_vm.product.nature === 'voucher') && _vm.voucherGroups.length === 0)?_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleAside(null, 'voucher_group')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Group")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.voucherGroups},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.stock)+" ("+_vm._s(item.stock_original)+") ")])]}},{key:"item.template",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.template ? item.template.nicename : 'n/a')+" ")])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.applicable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.applicable_at)))]):_c('span',[_vm._v("Any time.")])])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.last_purchasable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.last_purchasable_at)))]):_c('span',[_vm._v("Never.")])])]}},{key:"item.expire",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.expires_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.expires_at)))]):_c('span',[_vm._v("Never expires")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleAside(item, 'voucher_group')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update Group")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleGroupGenerator(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCog)+" ")]),_c('span',[_vm._v("Generate Vouchers")])],1)],1)],1)],1)]}}],null,true)})],1),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.t(_vm.sku.name))+"'s all Vouchers ")]),_c('v-card-text',[(_vm.ready)?_c('voucher-previewer',{attrs:{"rule":null,"sku":_vm.sku},on:{"updated":_vm.refresh}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }